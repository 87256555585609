<template>
  <div class="action__inner">
    <a-form :model="dataSource" :rules="rules" @finish="sendDeposit" name="deposit" ref="deposit">
      <a-form-item ref="personal" name="personal" class="input__wrapper" :label="i18n.t('start.personal')">
        <a-input
          style="width: 100%"
          :value="
            i18n.t('start.inputAccount', {
              name: i18n.t('start.name' + mainAccount.attributes.kind),
              amount: mainAccount.attributes.amount,
            })
          "
          :disabled="true"
          size="large"
          type="text"
        />
      </a-form-item>
      <a-form-item ref="amount" name="amount" class="input__wrapper" :label="i18n.t('start.summ')">
        <a-input
          v-model:value="dataSource.amount"
          size="large"
          style="width: 100%"
          type="number"
          :required="true"
          step="0.01"
        >
          <template #addonAfter> <p>USDT</p> </template>
        </a-input>
      </a-form-item>
      <a-form-item ref="currency" name="currency" class="input__wrapper" :label="i18n.t('start.currency')">
        <a-select
          v-model:value="dataSource.exchange_currency"
          size="large"
          ref="select"
          :required="true"
          @change="updateCryptoAmount"
          style="width: 100%"
        >
          <a-select-option v-for="currency of depositCurrencies" :value="currency.value" :key="currency.value">
            <p>
              <i>
                <img
                  :src="require('@/static/img/icon/' + currency.label + '.svg')"
                  class="crypto-icon"
                  :alt="currency.label"
                />
              </i>
              &nbsp;
              {{ currency.label }}
            </p>
          </a-select-option>
        </a-select>
        <p v-if="!isLoadingCrypto">
          {{ i18n.t('start.total') }}: {{ cryptoAmount }} {{ dataSource.exchange_currency }}
        </p>
        <a-spin size="small" v-else />

        <!--<a-radio-group v-model:value="dataSource.kind" size="large" :required="true">
                <a-radio :value="2">
                  CoinPayments
                </a-radio>
              </a-radio-group>-->
      </a-form-item>
      <sdButton
        class="action__button"
        size="large"
        type="primary"
        :disabled="isLoading || errorAmount"
        htmlType="submit"
        raised
      >
        {{ i18n.t('start.deposit') }}
      </sdButton>
      <!--<p class="action__more-info" @click="changePayInfo">
              <sdFeatherIcons type="info" size="20" /> {{ i18n.t('start.moreInfo') }}
            </p>-->
    </a-form>
  </div>
  <!--<div class="action__inner" v-if="visiblePayHint">
          <p class="action__info">
            {{ i18n.t('start.actionDepositHintFirst') }} <br />
            {{ i18n.t('start.actionDepositHintSecond') }} <br />
            {{ i18n.t('start.actionDepositHintThird') }} <br />
            {{ i18n.t('start.actionDepositHintFourth') }} <br />
            {{ i18n.t('start.actionDepositHintFifth') }}
            <router-link :to="{ name: 'faq', params: { modal: true } }">{{
              i18n.t('start.actionDepositHintForm')
            }}</router-link>
          </p>
        </div>-->
</template>

<script>
import { computed, onMounted, reactive, ref } from 'vue';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';
import { depositCurrencies } from '@/config/helpers/JSONdata';

const DepositModal = {
  name: 'DepositModal',
  props: { error: Boolean },
  emits: ['dataNotSended', 'dataSended'],
  setup(props, { emit }) {
    const i18n = useI18n();
    const { state, dispatch } = useStore();

    const dataSource = ref({
      amount: 0,
      exchange_currency: 'BTC',
    });

    const errorAmount = ref(false);

    const isLoading = computed(() => state.accounts.fileLoading);
    const cryptoAmount = computed(() => dataSource.value.amount * state.accounts.cryptoAmount);
    const mainAccount = computed(() => state.accounts.mainAccount);
    const isLoadingCrypto = computed(() => state.accounts.cryptoLoading);
    const amountValidation = () => {
      if (!rules.amount[0].trigger[1]) {
        rules.amount[0].trigger[1] = 'change';
      }
      const validate = { min: dataSource.value.exchange_currency == 'ETH' ? 37 : 1, max: 99999999 };
      if (validate.min > dataSource.value.amount || validate.max < dataSource.value.amount) {
        errorAmount.value = true;
        return Promise.reject(
          i18n.t('messages.errorAmount', validate, dataSource.value.amount > validate.max ? 2 : 1) + ' USDT',
        );
      } else {
        errorAmount.value = false;
        return Promise.resolve();
      }
    };

    const rules = reactive({
      amount: [
        {
          trigger: ['blur'],
          type: 'number',
          validator: amountValidation,
        },
      ],
    });

    const updateCryptoAmount = () => {
      dispatch('getCryptoAmount', {
        exchange_currency: dataSource.value.exchange_currency,
      });
    };

    const sendDeposit = () => {
      dispatch('sendDeposit', dataSource.value).then(() => {
        if (props.error) {
          emit('dataNotSended');
        } else {
          emit('dataSended');
        }
      });
    };

    onMounted(() => {
      dataSource.value.exchange_currency = 'BTC';
    });
    return {
      depositCurrencies,
      i18n,
      dataSource,
      errorAmount,
      isLoading,
      cryptoAmount,
      mainAccount,
      rules,
      isLoadingCrypto,
      updateCryptoAmount,
      sendDeposit,
    };
  },
};
export default DepositModal;
</script>

<style></style>
